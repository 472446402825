@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.body {
  font-family: "Inter", sans-serif;
}

.nav__dasboard-container {
  display: flex;
}

.nav_dashboard_izq {
  display: flex;
  flex-direction: column;
  width: 15%;

  min-width: 300px;
  
}

.nav_dashboard_izq-top {
  height: 150px;
  display: flex;
  justify-content: start;
  font-size: 24px;
  padding: 0 48px;
}

.title-dash:visited,
.title-dash:link {
  color: #6c61dd;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  align-self: center;
  text-decoration: none;
}
.layers_dash {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  padding: 0 48px;
  display: flex;
  gap: 20px;
  flex-direction: column;

}

.dash-link {
  font-size: 20px;
  text-decoration: none;
  color: #fff;
}

.layers_dash_opcions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  font-weight: 400;

}

.config_dash {
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  padding: 48px;
}

#cerrar_ses {
  color: #fa9696;
}
#cerrar_ses:hover {
  color: #fff;
  cursor: pointer;
  transition-duration: 0.8s;
  background-color: #f22626;
  border-radius: 10px;
}
.dash_dere {
  background-color: #e0e0e0;
  width: 85vw;
  height: 100vh;
  top: 0;
}
.header_dash {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  align-items: center;
  width: 100%;
  height: 150px;
  padding: 24px;
  justify-content: space-between;
}
.search_dash {
  font-size: 24px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.account_dash {
  display: flex;
  align-items: center;
}

.img_perfil_dash {
  height: 32px;
}

.logo-image-balance{
  height: 24px;
  width: 24px;
}
.letter_dash_porfile {
  position: absolute;
  left: 90%;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  align-self: center;
}
.opcions_contain {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  flex: 1 2 0;
}

.section__dashboard-container {
  margin: 0 24px 24px 24px;
  height: calc(100% - 174px);
}