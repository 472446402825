.section__balance-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: left; /* Center the movie posters container */
}

.section__balance-item-title {
  font-size: 20px;
  margin-bottom: 10px; /* Add some spacing below the title */
  
}

.section__balance-item {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Create a responsive grid for posters */
  gap: 20px; /* Add some spacing between posters */
  background-color: #ffff;
  width: 60vw; /* Slightly increase the width for better display */
  padding: 20px; /* Padding inside the container */
  border-radius: 8px;
  box-shadow: 
    4.5px 4.7px 2.2px rgba(0, 0, 0, 0.062),
    8.9px 9.3px 5.3px rgba(0, 0, 0, 0.082),
    13.5px 14.2px 10px rgba(0, 0, 0, 0.095),
    19.3px 20.2px 17.9px rgba(0, 0, 0, 0.106),
    29.2px 30.6px 33.4px rgba(0, 0, 0, 0.12),
    62px 65px 80px rgba(0, 0, 0, 0.15);
    max-height: 80vh;  /* you can adjust this value as per your design preference */
    overflow-y: auto;  /* this allows the container to scroll vertically when its contents overflow */
  }

.section__balance-item-title-mib {
  font-size: 20px;
  color: #6c61dd;
  margin-bottom: 10px; /* Add some spacing below this title */
}

.img_grafica_balance {
  width: 100%; /* Full width within grid cell */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional, to make the posters rounded */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1); /* Optional, adds a light shadow to each poster */
}
