.section__balance-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section__balance-item-title {
  font-size: 20px;
}

.section__balance-item {
  background-color: #ffff;
  width: 58vw;
  height: 60vh;
  flex: 1;
  border-radius: 8px;
  box-shadow:
  4.5px 4.7px 2.2px rgba(0, 0, 0, 0.062),
  8.9px 9.3px 5.3px rgba(0, 0, 0, 0.082),
  13.5px 14.2px 10px rgba(0, 0, 0, 0.095),
  19.3px 20.2px 17.9px rgba(0, 0, 0, 0.106),
  29.2px 30.6px 33.4px rgba(0, 0, 0, 0.12),
  62px 65px 80px rgba(0, 0, 0, 0.15);
border-radius: 10px;
position: relative;
left: 15%;
}
.section__balance-item-title-mib{
  font-size: 20px;
  color: #6c61dd;
  position: relative;
  left: 51px;
  top: 25px;
  width: 120px;
}
.img_grafica_balance{
  width: 56vw;
  height: 60vh;
  position: relative;
  top: 8%;
  left: 1%;

}
