.section__grupos-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section__grupos-item-title {
  font-size: 20px;
}

.section__grupos-item {
  background-color: #f7f7f7;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.section__grupos-item-container {
  /* crear el fondo blanco del tamaño del div */
  background-color: #fff;
  max-width: 350px;
  width: 100%;
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.section__grupos-item-container--img {
  width: 60%;
  height: 50%;
  background-color: #f7f7f7;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.section__grupos-item-container--img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.section__grupos-item-container--price {
  color: #fff;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  background-color: #6c61dd;
  padding: 10px;
}

.section__grupos-item-container--img .imagen {
  object-fit: contain;
  height: 100px;
  width: 100px;
}

.section__grupos-item-container--text {
  height: 100px;
}

.section__grupos-container-flex{
  display: flex;
  gap: 25px;
  margin-bottom: 8px;
}

.search_dash-button {
  padding: 4px 8px;
  border-radius: 8px;
}

.container-input {
  position: absolute;
  width: 30%;
  height: 30%;
  background-color: #505050;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  padding: 24px;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  font-size: 24px;
  color: white;
}



.container-input-title {

}
.container-input-button {
  margin-top: 20px;
  padding: 8px 16px;
}