* {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  --main-general-color: #6c61dd;
  --main-bg-color: #202020;
  --secundary-bg-color: #35363a;
  --main-font-color: #ffffff;
  --secundary-font-color: #acacac;
}

html {
  background-color: var(--main-bg-color);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--main-bg-color);
}

/* RESUSABLE CLASSES */

.primary-button:link,
.primary-button:visited {
  text-decoration: none;
  color: var(--main-font-color);
  padding: 8px 16px;
  background-color: var(--main-general-color);
  border-radius: 24px;
  font-weight: 600;
  font-size: 18px;
}

.primary-button:active,
.primary-button:hover {
}

.container {
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
}

.subtitle {
  text-align: center;
  font-size: 60px;
  color: var(--main-font-color);
  font-weight: 700;
  margin-bottom: 48px;
}

/* HEADER START */
.header {
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 60px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.header::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--main-bg-color);
  z-index: -1;
  overflow: hidden;
  opacity: 0.8;
  filter: blur(5px);
}

.header__nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header__nav--left {
  display: flex;
  align-items: center;
}

.header__nav-logo:link,
.header__nav-logo:visited {
  color: var(--main-general-color);
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
}

.header__nav--right {
  display: flex;
  gap: 50px;
  align-items: center;
}

.header__nav-link:link,
.header__nav-link:visited {
  color: var(--main-font-color);
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
}

.header__nav-link:active,
.header__nav-link:hover {
}

/* HEADER END */

/* SECTION HERO START */
.section__hero {
  margin-top: 100px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 600px;
  align-items: center;
}

.section__hero--left {
}

.section__hero--left-container {
}

.section__hero-title {
  font-size: 64px;
  color: var(--main-font-color);
  margin-bottom: 48px;
  font-weight: 700;
}

.section__hero-title span {
  font-size: 64px;
  color: var(--main-general-color);
}

.section__hero-description {
  font-size: 24px;
  color: var(--secundary-font-color);
  margin-bottom: 24px;
}

.section__hero--left-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}

.section__hero--left-button {
}

.section__hero--left-button-secundary:link,
.section__hero--left-button-secundary:visited {
  color: var(--main-font-color);
  text-decoration: none;
  font-size: 18px;
  font-size: 500;
}

.section__hero--right {
}

.section__hero--right-container {
}

.section__hero--right-img {
  width: 100%;
  height: 100%;
}

/* SECTION HERO END */

/* SECTION PARTNER START */

.section__partner {
  width: 100%;
  height: 100px;
  background-color: var(--secundary-bg-color);
  margin-bottom: 200px;
}

.section__partner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.section__partner-img {
  height: 40%;
  filter: brightness(.5) grayscale(1);
  opacity: .5;

}

/* SECTION PARTNER END */

/* SECTION SERVICES START */
.section__services {
  margin-bottom: 200px;
}

.section__services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
}

.section__services-service-box {
}

.section__services-service-image-box {
  height: 300px;
  background-color: var(--secundary-bg-color);
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 24px;
  display: grid;
  place-items: center;
}

.section__services-service-image {
  width: 80%;
  height: 80%;
}

.section__services-service-text {
  color: var(--main-font-color);
}

.section__services-service-text--tile {
  font-size: 32px;
  margin-bottom: 18px;
}

.section__services-service-text--description {
  font-size: 18px;
}

/* SECTION SERVICES END */

/* SECTION RECOMPENSAS START */

.section__rewards {
  margin-bottom: 200px;
}

.section__rewards-container {
}

.section__rewards-title {
  margin-bottom: 24px;
}

.section__rewards-description {
  font-size: 24px;
  color: var(--main-font-color);
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.section__rewards-container--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.section__rewards-reward-box {
  width: 100%;
  background-color: var(--secundary-bg-color);
  height: 300px;
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.section__rewards-reward-box-title {
  font-size: 24px;
  color: var(--secundary-font-color);
  font-weight: 600;
  line-height: 1.8;
  
}

.section__rewards-reward-box:nth-child(1) {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.section__rewards-reward-box:nth-child(1) .section__rewards-reward-box-title{
  font-size: 40px;
  color: var(--secundary-font-color);
  font-weight: 600;
  line-height: 1.8;
}

.section__rewards-reward-box:nth-child(1) .section__rewards-reward-image{
  width: 100%;
}

.section__rewards-reward-image {
  width: 50%;
}


/* SECTION HOW IT WORKS START */

.section__how {
  margin-bottom: 200px;
}

.section__how-container {
}

.section__how-title {
  margin-bottom: 24px;
}

.section__how-description {
  font-size: 24px;
  color: var(--main-font-color);
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.section__how-container--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
}

.section__how-text-box {
  display: grid;
  place-items: center;
  padding: 48px;
  border-radius: 16px;
}

.section__how-text--tile {
  font-size: 48px;
  color: var(--main-font-color);
  margin-bottom: 24px;
}

.section__how-how-text--description {
  color: var(--secundary-font-color);
  font-size: 24px;
  font-weight: 400;
}

.section__how-image-box {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 100%;
  height: 400px;
  justify-self: start;
}

.section__how-image-box:nth-child(3) {
  justify-self: start;
}

.section__how-image {
  height: 100%;
  width: 100%;
}

.metamask-img {
  height: 80%;
  width: 80%;
}

/* SECTION HOW IT WORKS STARTS */

/* SECTION SUBCRIPTIONS STARTS */
.section__subscriptions {
  margin-bottom: 160px;
}

.section__subscriptions-container {
}

.section__subscriptions-top {
}

.section__subscriptions-title {
  margin-bottom: 24px;
}

.section__subscriptions-description {
  font-size: 24px;
  color: var(--main-font-color);
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.section__subscriptions-image-box {
  width: 100%;
  height: 500px;

  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 48px;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.section__subscriptions-image {
  width: 90%;
  filter: drop-shadow(30px 10px 4px rgba(0, 0, 0, 0.6)) brightness(.7);
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.section__subscriptions-image:nth-child(2) {
  grid-row: 2;
  grid-column: 2;
}

.section__subscriptions-image:nth-child(3) {
  grid-row: 1;
  grid-column: 3;
}

.section__subscriptions-image:nth-child(4) {
  grid-row: 2;
  grid-column: 4;
}

.section__subscriptions-image:nth-child(5) {
  grid-row: 1;
  grid-column: 5;
}

@keyframes float {
  0% {

    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

/* SECTION SUBCRIPTIONS END */

/* SECTION ABOUT START */
.section__about {
  margin-bottom: 200px;
}

.section__about-container {
}

.section__about-top {
}

.section__about-title {
  margin-bottom: 24px;
}

.section__about-description {
  font-size: 24px;
  color: var(--main-font-color);
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 48px;
}

.section__about-container-inner {
  position: relative;
}

.roadmap-title {
  text-align: center;
  color: var(--secundary-font-color);
  font-size: 32px;
  margin-bottom: 24px;
}

.section__about-container-inner-grid {
}

.roadmap-line {
  position: absolute;
  left: 50%;
  z-index: 0;
  width: 4px;
  height: 100%;
  background-color: var(--main-general-color);
  transform: translate(-50%, 0);
}

.section__about-container-inner-grid-item {
  display: grid;
  margin-bottom: 2em;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 6em 1fr;
  grid-template-columns: 1fr 6em 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  width: 100%;
  color: var(--main-font-color);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stage {
  display: flex;
  width: 5em;
  height: 5em;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--main-general-color);
  font-weight: 500;
  z-index: 2;
}

.stage-text {
  font-size: 20px;
  font-weight: 600;
  padding: 16px;
  text-align: center;
}

.stage-text-title {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: var(--main-general-color);
}

/* SECTION ABOUT END */

.footer {
  width: 100%;
  min-height: 200px;
  background-color: var(--secundary-bg-color);
}

.align-right {
 
}

.footer-container {
  padding: 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.footer-container-text {
  margin-top: 20px;
  color: var(--secundary-font-color);
}

